@font-face {
  font-family: "CircularXX-Bold";
  src: local("CircularXX-Bold"),
    url(./assets/fonts/CircularXX-Bold.otf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "CircularXX-Book";
  src: local("CircularXX-Book"),
    url(./assets/fonts/CircularXX-Book.otf) format("truetype");
  font-display: swap;
}