
/* Global Styles */
html {
  height: 100%;
  scrollbar-gutter: stable;
}
:root {
  --primary-color: #dc3545;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  background-color: #1d0c50;
  width: 10px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #351cb3; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #392b83; 
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "CircularXX-Book", sans-serif !important;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #00002b;
  color: #fff;
  padding: 0 !important;
  min-width: 320px;
  overflow-anchor: none;
  scroll-behavior: smooth;
  min-height: 100%;
  flex-direction: column;
}

.grecaptcha-badge { 
  visibility: hidden;
}

ul {
  list-style: none;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-success {
  color: var(--success-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.copy-link-input {
  flex: 1;
  border: 1px solid #ccc;
}
/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

a:link,
a:visited {
  color: #fff;
  text-decoration: none;
}


/* header */
header .MuiGrid-container {
  align-items: center;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
/*
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}
.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
} */

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

/* .btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}
.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}
.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}
.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}
.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}
.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}
.btn:hover {
  opacity: 0.8;
} */

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  color: black
}

input[type="submit"],
button {
  font: inherit;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

/* Navbar */
/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  margin-bottom: 1rem;
}
.navbar ul {
  display: flex;
}
.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}
.navbar a:hover {
  color: var(--light-color);
}
.navbar .welcome span {
  margin-right: 0.6rem;
} */
/* Navigation Bar */
.navbar {
  background-color: #0f0b46 !important;
  padding: 10px 24px;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.mobile-nav {
  background-color: #141445;
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 !important;
  z-index: 99;
}
.navbar-brand .logo {
  padding-top: 10px;
}
.navbar .nav-link {
  font-size: 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.navbar .navbar-button {
  min-width: 100px;
  max-width: 100px;
  border-radius: 20px;
}
.navbar .mobile-nav-icon {
  cursor: pointer;
  color: #fb2961;
  -webkit-tap-highlight-color: transparent;
  height: 50px;
}
.navbar .mobile-nav-link {
  font-weight: bold;
  color: #f41e73;
  text-decoration: none !important;
}
.navbar .mobile-menu-logo img {
  margin: 0 5px;
}
.navbar a {
  color: #fff;
}
.navbar .coin {
  width: 23px;
}
.navbar .btn-yellow {
  margin-right: 9px;
}
.navbar .btn-yellow img {
  margin-right: 4px;
}
.navbar .btn-yellow-coins {
  font-size: 12px !important;
  width: 100px !important;
  margin-top: 5px !important;
}
.navbar .menu-coin img {
  margin-right: 2px !important;
}
.navbar .menu-item:hover {
  background-color: #0f0b46 !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown .dropdown-content {
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1),
    opacity 0.3s ease-in-out;
  transform: translateY(-4rem);
  list-style: none;
  padding: 12px 16px 12px 24px;
  min-width: 200px;
  margin: 9px 0;
  text-align: left;
  position: absolute;
  background: #271d58;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
}
.dropdown:hover .dropdown-content {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.dropdown .dropdown-content li {
  padding: 8px 0;
}
.dropdown:hover .dropdown-content:hover {
  display: block;
}
.close-icon {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.close-gift-icon {
  margin: 0 0 0 auto;
  display: block !important;
  color: #FFF;
  font-size: 20px !important;
}
/* Avatar Dropdown */
.userdropdown-content {
  display: block;
  position: absolute;
  min-height: 200px;
  right: 24px;
  top: 67px;
  background: #271d58;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  box-sizing: border-box;
  transition: height ease-out 2s;
  -webkit-transition: height ease-out 2s;
  -moz-transition: height ease-out 2s;
}
.nav-header {
  padding: 12px;
}
.nav-header .user-profile {
  margin-right: 10px;
  padding: 10px;
}
.nav-header,
.nav-header .coins {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav-header .coins img[alt="coin"] {
  width: 100%;
  max-width: 16px;
}
.nav-header .coin-count {
  font-size: 14px;
  line-height: 16px;
  color: #ffd700;
}
.nav-header .user-text h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 5px;
}
.nav-body {
  box-sizing: border-box;
}
.nav-body ul,
.nav-footer ul {
  padding: 0;
  margin: 0;
}
.nav-body ul li,
.nav-footer ul li {
  display: block;
  text-align: left;
  padding: 10px 10px 10px 20px;
}
/* End of Navigation Bar */

/* Profile Page */
.profile-tab .tab {
  min-width: 0 !important;
  text-transform: capitalize !important;
  font-size: 14pt;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #fb2961;
  margin: 30px 40px 8px 0 !important;
  padding-left: 0 !important;
  opacity: 0.5 !important;
}
/* .profile-tab .MuiTabs-flexContainer {
  display: flex;
  justify-content: center;
} */
.profile-tab .MuiTab-wrapper {
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.profile-tab .Mui-selected {
  border-top: 2px solid transparent;
  opacity: 1 !important;
}
.tabPanels {
  margin-bottom: 62px !important;
}
/* End of Profile Page */

/* Small Screen */
@media (max-width: 960px) {
  .navbar {
    background-color: #00002b !important;
    padding: 8px 10px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.09);
  }
}

/* Medium Screen */
@media (max-width: 1280px) and (min-width: 960px) {
  .navbar {
    padding: 10px 24px;
  }
  .navbar .nav-link {
    font-size: 16px;
  }
  .dropdown .dropdown-content {
    min-width: 180px;
    padding: 12px 16px 12px 18px;
  }
}

/* Other Media Queries*/
@media (max-width: 400px) {
  .profile-tab .tab {
    font-size: 14pt;
    letter-spacing: 0.4px;
    margin: 30px 20px 8px 0 !important;
  }
}

@media (max-width: 1040px) and (min-width: 960px) {
  .navbar .coin {
    width: 20px;
  }
  .navbar .btn-yellow img {
    margin-right: 1px;
  }
}

@media (max-width: 1280px) and (min-width: 1040px) {
  .navbar .coin {
    width: 21px;
  }
  .navbar .btn-yellow img {
    margin-right: 2px;
  }
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  /* .navbar {
    display: block;
    text-align: center;
  }
  .navbar ul {
    text-align: center;
    justify-content: center;
  } */
}

/* buttons */
.btns {
  color: #fff !important;
  border-radius: 25px !important;
  width: 100%;
  font-size: 16px !important;
  text-transform: none !important;
  padding: 0 !important;
  max-width: 126px;
  height: 35px;
}
.btn-red {
  background: linear-gradient(90deg, #fb2961 6.26%, #d301bc 100%);
}
.btn-yellow {
  background: linear-gradient(90deg, #ff9b00 6.26%, #ffc000 100%);
}

.btn-okay {
  color: #fff !important;
  border: 0;
  outline: 0;
  border-color: #fff;
  border-radius: 25px !important;
  text-transform: none !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  background: linear-gradient(90deg, #fb2961 1.07%, #d200be 100%);
  height: 40px;
  font-size: 13px !important;
  margin-left: auto !important;
  width: 100%;
  cursor: pointer;
}

.btn-okay-report {
  color: #fff !important;
  border: 0;
  border-radius: 25px;
  background: linear-gradient(90deg, #fb2961 1.07%, #d200be 100%);
  font-size: 13px !important;
  margin: 10px;
  width: 70px;
  height: 40px;
}

.btn-cancel-report {
  color: #fff !important;
  border: 0;
  border-radius: 25px;
  background: #ced1d4 !important;
  font-size: 13px !important;
  width: 70px;
  height: 40px;
}

.btn-report {
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0);
  border-radius: 25px !important;
  text-transform: none !important;
  background: linear-gradient(90deg, #fb2961 1.07%, #d200be 100%);
  height: 40px;
  font-size: 13px !important;
  width: 90%;
}

.btn-close {
  color: #fff !important;
  border-color: #ced1d4;
  border-radius: 25px !important;
  text-transform: none !important;
  background: #ced1d4 !important;
  height: 45px;
  font-size: 13px !important;
  margin-left: auto !important;
  width: 100%;
}

/* Profile Page Button */
.btn-profile-follow-desktop {
  background: linear-gradient(90deg, #fb2961 1.07%, #d200be 100%);
  width: 20vw;
  height: 52px;
  font-size: 1rem !important;
  margin-left: auto !important;
}
.btn-profile-follow-mobile {
  background: linear-gradient(90deg, #fb2961 3.96%, #d200be 100%);
  max-width: 92px;
  height: 34px;
  font-size: 0.8rem !important;
  margin-left: auto !important;
}
.btn-profile-following-mobile {
  background: linear-gradient(90deg, #29eee9 3.96%, #29eee9 100%);
  max-width: 92px;
  height: 34px;
  font-size: 0.8rem !important;
  margin-left: auto !important;
  color: #333;
}
.btn-profile-following-desktop {
  background: linear-gradient(90deg, #29eee9 1.07%, #29eee9 100%);
  width: 20vw;
  height: 52px;
  font-size: 1rem !important;
  margin-left: auto !important;
  color: #333;
}
/* End of Profile Page Button */

/* main content */
.main-content {
  padding: 23px 10px;
}

/* SIDEBAR */
.MuiDrawer-paper {
  position: relative !important;
  background: #00012b !important;
  border-right: 2px solid rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
  padding: 23px 0;
}
.streamerList {
  padding: 0 0 33px !important;
}
/* titles */
.titles {
  padding: 10px 22px;
  text-overflow: ellipsis;
  color: #fb2961;
}
/* dots */
.dots {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 22px;
  background: #717171;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto;
}
.online {
  background: #fb2961;
}
.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  min-height: 0px !important;
}
.showMore {
  color: #fb2961 !important;
  text-align: center;
  display: block;
  text-decoration: underline !important;
}
.username {
  width: 100%;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.profile-link {
  display: flex;
  align-items: center;
}
.profile-link:hover {
  text-decoration: none !important;
}

/* sidebar closed */
.sidebar-closed .titles {
  text-align: center;
  padding: 10px 5px;
  font-size: 14px;
}
.sidebar-closed .MuiListItem-root {
  padding: 7px 15px;
}
.sidebar-closed .streamerList {
  padding: 0 !important;
}
.sidebar-closed .dots {
  top: 9px;
  right: 16px;
  margin: 0;
}
.sidebar-closed .toolbar {
  justify-content: center;
}
.sidebar-closed .username {
  display: none;
}
.following {
  margin-top: 40px;
}
.MuiSvgIcon-root {
  color: #29efe9;
}
.MuiSvgIcon-root:active {
  border: none;
}

/* cookie banner */
.cookieBanner {
  padding: 0 15px;
  position: fixed;
  box-sizing: border-box;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999999;
}
.CookieConsent {
  align-items: center !important;
  border-radius: 5px;
  right: 0;
  margin: 0 auto;
  max-width: 625px;
  background: #2d127d !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box;
  padding: 1rem !important;
}
.CookieConsent > div {
  margin: 0 !important;
}
.cookie-btn-accept {
  border-radius: 3px !important;
  background: linear-gradient(135deg,#fb2861 0,#fb2861 100%) !important;
  font-size: 14px !important;
  line-height: 1.5;
  color: #FFF !important;
  font-family: "CircularXX-Book", sans-serif !important;
  box-sizing: border-box;
  width: 100%;
  max-width: 89px;
  height: 31px;
  margin: 0 !important;
  padding: 0 15px;
}
.cookie-msg {
  font-size: 13px !important;
  font-family: "CircularXX-Book", sans-serif !important;
  word-wrap: break-word;
  margin-right: 10px !important;
}

/* max width 895 */
@media screen and (max-width: 895px) {
  .cookieBanner{
    bottom: 65px;
  }
}

/* max width 456 */
@media screen and (max-width: 456px) {
  .CookieConsent > div:first-of-type {
    flex: 1 0 200px !important;
  }
}

/* max width 375 */
@media screen and (max-width: 375px) {
  .CookieConsent > div:first-of-type {
    flex: 1 0 150px !important;
  }
}

/* end of cookie banner */

@media screen and (max-width: 992px) {
  .MuiDrawer-root {
    display: none;
  }
}

.swal2-container {
  z-index: 9999 !important;
}
.search-bar-container {
  display: flex;
  text-align: center;
  justify-content: flex-end;
}

/* loader */
.loader {
  width: 3em;
  height: 3em;
  border-radius:50%;
  border: 0.5em solid rgba(0, 25, 25, 0.2);
  position:relative;
  z-index:1;
}
.loader::before {
  content:'';
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 3em;
  height: 3em;
  border: 0.5em solid rgba(0, 25, 25, 0.2);
  border-left: 0.5em solid #261067;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  position: absolute;
}
.loader--hide {
  opacity: 0;
}
.loader > img{
  margin:20% 10%;
}
@keyframes load8 {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
@keyframes spinlogo {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

.top-gradient-overlay {
  position: absolute;
  top: 0;
  width: 100%; 
  height: 2.5rem;
  background: linear-gradient(180deg, rgba(0, 0, 43, 0.0001) 0%, #00002B 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.bottom-gradient-overlay {
  position: absolute;
  bottom: 0;
  width: 100%; 
  height: 2.5rem;
  background: linear-gradient(0deg, rgba(0, 0, 43, 0.0001) 0%, #00002B 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}